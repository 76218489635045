<template>
<div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <router-link :to="{ name: 'house_flyer_editor' }">
                <button class="btn btn-control">
                    <img alt="Return back" src="@/assets/icons/bold-icon previous.svg">
                </button>
            </router-link>
            <h3 class="page-header-title ms-2">Open House Flyer</h3>
            <span class="subtitle">Preview</span>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-3 mt-md-0">
            <router-link :to="{ name: 'house_flyer_editor' }" class="text-decoration-none">
                <base-button title="Edit" action="secondary" />
            </router-link>
            <base-button title="Download" action="primary" @click-btn="savePdf" :loading="isDownload" :disabled="isDownload" class="ms-2" />
        </div>
    </div>

    <!--  Page Content  -->
    <div class="page-content p-normal d-flex justify-content-center">
        <pdf :src="src" :page="1" class="page-preview" />
    </div>
</div>
</template>

<script>
import pdf from 'vue-pdf'
import {getBackendUrl} from "@/utils/backendUrl";
import BaseButton from '../../components/BaseButton.vue';

export default {
    name: 'OpenHouseFlyerPreview',
    components: {
        pdf,
        BaseButton
    },
    data() {
        return {
            isDownload: false,
            src: null,
            baseUrl: getBackendUrl() + `/api/v1/open-house-flyer/download/${this.$store.getters.getUserId}`,
            formData: {}
        }
    },
    methods: {
        savePdf() {
            this.isDownload = true
            this.formData.isDownload = 1
            this.$http.get(`/api/v1/open-house-flyer/download/${this.$store.getters.getUserId}`, {
                params: this.formData,
                responseType: 'arraybuffer'
            }).then(res => {
                this.generateFile(res);
            }).catch(() => {
                this.pushAlert('error', 'Failed Generate')
            }).finally(() => this.isDownload = false)
        },
        generateFile(res) {
            let fileURL = window.URL.createObjectURL(new Blob([res.data], {
                type: 'application/pdf'
            })),
            fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'open_house_flyer.pdf');

            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
        },
        prepareFormData() {
          let flyerData = this.$store.getters.getFlyerData

          let address = flyerData.editor.houseDetails.property.address
          address = address.replace(/\r/g,'')
          address = address.replace(/\n/g, ' ~/ ');

          let desc = flyerData.editor.houseDetails.property.desc
          desc = desc.replace(/\r/g,'')
          desc = desc.replace(/\n/g, ' ~/ ');

            this.formData = {
                isDownload: 0,
                houseDetail_price: flyerData.editor.houseDetails.property.price,
                houseDetail_address: address,
                houseDetail_description: desc,
                houseDetail_bedrooms: flyerData.editor.houseDetails.total.bedrooms,
                houseDetail_baths: flyerData.editor.houseDetails.total.baths,
                houseDetail_size: flyerData.editor.houseDetails.total.size,
                houseDetail_lotSize: flyerData.editor.houseDetails.total.lotSize,
                theme: flyerData.editor.selectedTheme,
                // First Column
                loanDetails_first_type: flyerData.loanDetails.loanOptionFirst.types,
                loanDetails_first_rate: flyerData.loanDetails.loanOptionFirst.rate,
                loanDetails_first_apr: flyerData.loanDetails.loanOptionFirst.apr,
                loanDetails_first_downPaymentAmount: flyerData.loanDetails.loanOptionFirst.downPaymentAmount,
                loanDetails_first_downPaymentAmountDollars: flyerData.loanDetails.loanOptionFirst.downPaymentAmountDollars,
                loanDetails_first_fee: flyerData.loanDetails.loanOptionFirst.fee,
                loanDetails_first_loanAmount: flyerData.loanDetails.loanOptionFirst.loanAmount,
                loanDetails_first_loanTerms: flyerData.loanDetails.loanOptionFirst.loanTerms,

                empi_first_pi: flyerData.loanDetails.loanOptionFirst.principal,
                empi_first_fee: flyerData.loanDetails.loanOptionFirst.mortgageFee,
                empi_first_tih: flyerData.loanDetails.loanOptionFirst.taxesInsuranceDues,
                empi_first_tmp: flyerData.loanDetails.loanOptionFirst.totalMonthlyPayment,

                estimated_first_point: flyerData.loanDetails.loanOptionFirst.points,
                estimated_first_originationFee: flyerData.loanDetails.loanOptionFirst.originationFee,
                estimated_first_costFee: flyerData.loanDetails.loanOptionFirst.closingCostsSettlementFees,
                estimated_first_piDay: flyerData.loanDetails.loanOptionFirst.prepaidInterest.days,
                estimated_first_piAmount: flyerData.loanDetails.loanOptionFirst.prepaidInterest.amount,
                estimated_first_other: flyerData.loanDetails.loanOptionFirst.otherPrepaids,
                estimated_first_seller: flyerData.loanDetails.loanOptionFirst.sellerConcession,
                estimated_first_funds: flyerData.loanDetails.loanOptionFirst.estimatedFunds,

                // Second Column
                loanDetails_second_type: flyerData.loanDetails.loanOptionSecond.types,
                loanDetails_second_rate: flyerData.loanDetails.loanOptionSecond.rate,
                loanDetails_second_apr: flyerData.loanDetails.loanOptionSecond.apr,
                loanDetails_second_downPaymentAmount: flyerData.loanDetails.loanOptionSecond.downPaymentAmount,
                loanDetails_second_downPaymentAmountDollars: flyerData.loanDetails.loanOptionSecond.downPaymentAmountDollars,
                loanDetails_second_fee: flyerData.loanDetails.loanOptionSecond.fee,
                loanDetails_second_loanAmount: flyerData.loanDetails.loanOptionSecond.loanAmount,
                loanDetails_second_loanTerms: flyerData.loanDetails.loanOptionSecond.loanTerms,

                empi_second_pi: flyerData.loanDetails.loanOptionSecond.principal,
                empi_second_fee: flyerData.loanDetails.loanOptionSecond.mortgageFee,
                empi_second_tih: flyerData.loanDetails.loanOptionSecond.taxesInsuranceDues,
                empi_second_tmp: flyerData.loanDetails.loanOptionSecond.totalMonthlyPayment,

                estimated_second_point: flyerData.loanDetails.loanOptionSecond.points,
                estimated_second_originationFee: flyerData.loanDetails.loanOptionSecond.originationFee,
                estimated_second_costFee: flyerData.loanDetails.loanOptionSecond.closingCostsSettlementFees,
                estimated_second_piDay: flyerData.loanDetails.loanOptionSecond.prepaidInterest.days,
                estimated_second_piAmount: flyerData.loanDetails.loanOptionSecond.prepaidInterest.amount,
                estimated_second_other: flyerData.loanDetails.loanOptionSecond.otherPrepaids,
                estimated_second_seller: flyerData.loanDetails.loanOptionSecond.sellerConcession,
                estimated_second_funds: flyerData.loanDetails.loanOptionSecond.estimatedFunds,

                // Third Column
                loanDetails_third_type: flyerData.loanDetails.loanOptionThird.types,
                loanDetails_third_rate: flyerData.loanDetails.loanOptionThird.rate,
                loanDetails_third_apr: flyerData.loanDetails.loanOptionThird.apr,
                loanDetails_third_downPaymentAmount: flyerData.loanDetails.loanOptionThird.downPaymentAmount,
                loanDetails_third_downPaymentAmountDollars: flyerData.loanDetails.loanOptionThird.downPaymentAmountDollars,
                loanDetails_third_fee: flyerData.loanDetails.loanOptionThird.fee,
                loanDetails_third_loanAmount: flyerData.loanDetails.loanOptionThird.loanAmount,
                loanDetails_third_loanTerms: flyerData.loanDetails.loanOptionThird.loanTerms,

                empi_third_pi: flyerData.loanDetails.loanOptionThird.principal,
                empi_third_fee: flyerData.loanDetails.loanOptionThird.mortgageFee,
                empi_third_tih: flyerData.loanDetails.loanOptionThird.taxesInsuranceDues,
                empi_third_tmp: flyerData.loanDetails.loanOptionThird.totalMonthlyPayment,

                estimated_third_point: flyerData.loanDetails.loanOptionThird.points,
                estimated_third_originationFee: flyerData.loanDetails.loanOptionThird.originationFee,
                estimated_third_costFee: flyerData.loanDetails.loanOptionThird.closingCostsSettlementFees,
                estimated_third_piDay: flyerData.loanDetails.loanOptionThird.prepaidInterest.days,
                estimated_third_piAmount: flyerData.loanDetails.loanOptionThird.prepaidInterest.amount,
                estimated_third_other: flyerData.loanDetails.loanOptionThird.otherPrepaids,
                estimated_third_seller: flyerData.loanDetails.loanOptionThird.sellerConcession,
                estimated_third_funds: flyerData.loanDetails.loanOptionThird.estimatedFunds,

                // Agent Details
                agent_id: flyerData.selectedAgent.id,
                agent_name: flyerData.selectedAgent.fullName,
                agent_nmls: flyerData.selectedAgent.nmls,
                agent_phone: flyerData.selectedAgent.phone ? this.formatUSNumber(flyerData.selectedAgent.phone) : '',
                agent_position: flyerData.selectedAgent.position,

                // Realtor Details
                realtor_name: flyerData.realtor.fullname,
                realtor_dre: flyerData.realtor.nmls,
                realtor_phone: flyerData.realtor.phone ? this.formatUSNumber(flyerData.realtor.phone) : '',
                realtor_position: flyerData.realtor.position,

                disclaimer: flyerData.disclaimer
            }
        },
        convertQueryString(obj) {
            return Object.keys(obj).map(function(key) {
                return key + '=' + obj[key];
            }).join('&');
        }
    },
    mounted() {
        this.prepareFormData()
        this.src = pdf.createLoadingTask(this.baseUrl + '?' + this.convertQueryString(this.formData))
        this.src.promise.then(() => {
            console.log('Generated')
        })
    },
}
</script>

<style lang="scss" scoped>
.page-header {
    @media screen and (max-width: 568px) {
        flex-direction: column;
    }

    button {
        &.btn-outline-dark {
            min-width: 130px;
            border: 1px solid rgba(0, 0, 0, .6);
            border-radius: 8px;
            transition: all .3s;

            &:hover {
                background: #efefef;
            }
        }

        &.btn-primary {
            min-width: 150px;
            margin-left: 12px;
            background-color: #065AE4;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
            transition: all .3s;

            &:hover {
                background-color: #0555d9;
            }
        }
    }
}
.page-preview {
  display: inline-block;
  width: 100%;
  border: #0a0a0a 1px solid;
  margin-bottom: 30px;

  .annotationLayer {
      display: none;
  }
}
</style>
